import {
  Box,
  Button,
  Grid,
  Hidden,
  IconButton,
  Link,
  Typography,
  useMediaQuery,
} from "@mui/material";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import CalendarMonthSharpIcon from "@mui/icons-material/CalendarMonthSharp";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import MoreHorizSharpIcon from "@mui/icons-material/MoreHorizSharp";
import React, { useEffect, useRef, useState } from "react";
import { bg, logo } from "../Images";
import PresaleInfo from "../Pages/PresaleInfo";
import Tokenomics from "../Pages/Tokenomics";
import Roadmap from "../Pages/Roadmap";
import Faq from "../Pages/FAQs";
import ContactUs from "../Pages/ContactUs";

import HowToBuy from "../Pages/HowToBuy";
import SwipeableTemporaryDrawer from "./SwipeableTemporaryDrawer";
import Tabss from "./Home/Tabss";
import BuyCard from "./Home/BuyCard";

const Donald = ({
  setEstimatedPrice,
  estimatedPrice,
  setTotalFollowers,
  totalFollowers,
}) => {
  const [showBox, setShowBox] = React.useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isFixed, setIsFixed] = useState(false);
  const tabRef = useRef(null);
  const placeholderRef = useRef(null);

  useEffect(() => {
    let ticking = false;

    const handleScroll = () => {
      if (!ticking) {
        requestAnimationFrame(() => {
          if (tabRef.current && placeholderRef.current) {
            const { top } = tabRef.current.getBoundingClientRect();
            const placeholderTop = placeholderRef.current.offsetTop;

            // Update the fixed state
            setIsFixed(top <= 0 && window.scrollY > placeholderTop);
          }
          ticking = false;
        });
        ticking = true;
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        borderLeft: "0.5px solid #2F3336",
        borderRight: "0.5px solid #2F3336",
        borderBottom: "0.5px solid #2F3336",
        // px: 1,
        boxSizing: "border-box",
        fontFamily: "HelveticaNeueBlack",
        position: "relative",
        overflow: "hidden",
        // px: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          // justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          position: { xs: "fixed", lg: "relative" },
          top: 0, // Stick to the top of the viewport
          left: "50%", // Center horizontally
          transform: "translateX(-50%)",
          background: "#000",
          zIndex: "10000",
          gap: "0px 10px",
          pl: 2,
          py: 1,
        }}
        // maxWidth="xl"
      >
        <Hidden lgUp>
          <SwipeableTemporaryDrawer />
        </Hidden>
        <Box>
          <Typography
            sx={{
              fontSize: { xs: "16px", md: "20px" },
              fontWeight: "bold",
              color: "#fff",
              fontFamily: "HelveticaNeueBlack",
              mt: 0.5,
              mb: 1,
              // pl: 4,
            }}
          >
            YourTrump
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundImage: `url(${bg})`,
          height: { xs: "150px", sm: "200px", md: "280px" },
          width: "100%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          position: "relative",
          // borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            width: { xs: "90px", sm: "120px", md: "150px" },
            height: { xs: "90px", sm: "120px", md: "150px" },
            position: "absolute",
            left: { xs: "10px", md: "25px" },
            bottom: { xs: "-50px", md: "-85px" },
          }}
        >
          <img
            src={logo}
            alt=""
            srcSet=""
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              border: "4px solid #000",

              // objectFit: "contain",
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "0 16px",
            mt: 2,
          }}
        >
          <IconButton sx={{ border: "2px solid #757575" }}>
            <MoreHorizSharpIcon sx={{ fontSize: "24px", color: "#fff" }} />
          </IconButton>
          <IconButton
            onClick={() =>
              window.open("https://www.google.com/search?q=yourtrump", "_blank")
            }
            sx={{ border: "2px solid #757575" }}
          >
            <SearchSharpIcon sx={{ fontSize: "24px", color: "#fff" }} />
          </IconButton>

          <Button
            onClick={() => window.open("https://x.com/realYourTrump", "_blank")}
            sx={{
              px: 2,
              py: 1,
              borderRadius: "20px",
              backgroundColor: "#fff",
              color: "#000",
              fontWeight: "bold",
              fontFamily: "HelveticaNeueBlack",
            }}
          >
            Follow
          </Button>
        </Box>
      </Box>
      <Box sx={{ mt: "40px", px: 0 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "4px 0",
            p: 2,
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "22px", sm: "22px", md: "30px" },
              fontWeight: "bold",
              color: "#fff",
              fontFamily: "HelveticaNeueBlackBold",
            }}
          >
            YourTrump
          </Typography>
          <Typography
            sx={{
              color: "grey",
              fontSize: {
                xs: "14px",
                sm: "18px",
                fontFamily: "HelveticaNeueBlack",
              },
            }}
          >
            $YTP
          </Typography>
        </Box>

        <Grid
          container
          sx={{ display: "flex", justifyContent: "space-between", px: 2 }}
        >
          <Grid item xs={12} md={12} sx={{ fontSize: "30px" }}>
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: "10px 0" }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: { xs: "16px", md: "24px" },
                    fontFamily: "HelveticaNeueBlack",
                    color: "#fff",
                    fontWeight: "400",
                  }}
                >
                  Trump’s followers set your coin’s listing price!
                </Typography>{" "}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  gap: "10px 10px",
                  flexDirection: { xs: "column", sm: "row" },
                }}
              >
                <Typography
                  to="https://Yourtrump.vip"
                  // to={https://x.com/realDonaldTrump}
                  target="_blank"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "#1D9BF0",
                  }}
                >
                  <InsertLinkIcon sx={{ color: "#fff" }} />
                  &nbsp;
                  <a
                    href="https://Yourtrump.vip"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <span style={{ color: "#1D9BF0" }}>yourtrump.vip</span>
                  </a>
                </Typography>
                <Typography
                  sx={{
                    color: "grey",
                    display: "flex",
                    alignItems: "center",
                    fontSize: { xs: "17px", md: "14px", lg: "17px" },
                    fontFamily: "HelveticaNeueBlack",
                  }}
                >
                  <CalendarMonthSharpIcon sx={{ color: "grey" }} />
                  <span
                    style={{
                      paddingLeft: "3px",
                    }}
                  >
                    Joined March 2009
                  </span>
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "0 10px",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: "16px", md: "20px" },
                    color: "#fff",
                    display: "flex",
                    gap: "0px 10px",
                    alignItems: "center",
                    fontFamily: "HelveticaNeueBlack",
                  }}
                >
                  <a
                    href="https://x.com/realDonaldTrump"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <span style={{ color: "red" }}>LIVE</span>
                  </a>
                  &nbsp;
                  {/* <TwitterUser fontSize="18px" />{" "} */}
                  <span style={{ fontFamily: "HelveticaNeueBlackBold" }}>
                    {totalFollowers.toLocaleString()}
                  </span>
                  <span style={{ color: "grey" }}> Followers</span>
                </Typography>
              </Box>
            </Box>
          </Grid>
          <>
            {/* Placeholder to maintain space when the element becomes fixed */}
            <div
              ref={placeholderRef}
              style={{
                height: isFixed
                  ? `${tabRef.current?.offsetHeight || 0}px`
                  : "0px",
              }}
            ></div>
            <Box
              ref={tabRef}
              sx={{
                position: { md: isFixed ? "fixed" : "relative" },
                top: { xs: isFixed ? 30 : "auto", md: isFixed ? 0 : "auto" },
                zIndex: isFixed ? 10 : 0, // Ensure it stays above other content
                width: {
                  xs: isFixed ? "100%" : "100%",
                  md: isFixed ? "580px" : "100%",
                }, // Ensure it spans the width when fixed
                background: "#000",
                transition: "top 0.3s ease", // Smooth transition for `top` property
                willChange: "transform", // Optimize for smoother transitions
              }}
            >
              <Tabss />
            </Box>
          </>
        </Grid>

        <Box sx={{ mt: 6, boxSizing: "border-box" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "40px 0px",
              boxSizing: "border-box",
            }}
          >
            <Box
              sx={{
                display: { xs: "", md: "none" },
                width: "97%",
                mx: "auto",
              }}
            >
              <BuyCard
                totalFollowers={totalFollowers}
                setTotalFollowers={setTotalFollowers}
                estimatedPrice={estimatedPrice}
                setEstimatedPrice={setEstimatedPrice}
              />
            </Box>
            <PresaleInfo />
            <Tokenomics />
            <Roadmap />
            <HowToBuy />
            <Faq />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Donald;
