import React from "react";
import Parent from "./Components/Parent";
import { Box } from "@mui/material";
import Home from "./Components/Home/Home";
import { Route, Routes } from "react-router-dom";

const App = () => {
  return (
    <Box sx={{ background: "#000", boxSizing: "border-box" }}>
      <Routes>
        <Route path="/" element={<Parent />}>
          <Route index element={<Home />} />
        </Route>
      </Routes>
    </Box>
  );
};

export default App;
