import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import Donald from "../Donald";
import BuyCard from "./BuyCard";
import Disclaimer from "../../Pages/Disclaimer";
import TwitterUser from "../../Pages/TwitterUser";

const Home = ({
  setEstimatedPrice,
  estimatedPrice,
  setTotalFollowers,
  totalFollowers,
}) => {
  return (
    <Box
      sx={{
        background: "#000",
        width: "100%",
        boxSizing: "border-box",
        height: "auto",
      }}
    >
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          background: "#000",
          width: "100%",
          position: "relative",
        }}
      >
        <Grid
          item
          xs={12}
          md={7.7}
          lg={8}
          sx={{
            width: "100%",
            height: "auto",
            pt: { xs: 9, lg: 0 },
          }}
        >
          <Donald
            totalFollowers={totalFollowers}
            setTotalFollowers={setTotalFollowers}
            estimatedPrice={estimatedPrice}
            setEstimatedPrice={setEstimatedPrice}
          />
          <Disclaimer />
        </Grid>
        <Grid
          item
          xs={12}
          md={4.1}
          lg={3.68}
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Box
            sx={{
              position: "relative",
              boxSizing: "border-box",
              width: "100%",
            }}
          >
            <Box
              sx={{
                position: { xs: "", md: "fixed" },
                width: { xs: "100%", sm: "100%", md: "340px", lg: "370px" },
                top: { md: "70px", lg: "45px" },
                display: { xs: "none", md: "block" },
                mx: { xs: 2, md: 0 },
              }}
            >
              <BuyCard
                totalFollowers={totalFollowers}
                setTotalFollowers={setTotalFollowers}
                estimatedPrice={estimatedPrice}
                setEstimatedPrice={setEstimatedPrice}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Home;
